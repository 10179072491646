import { cx, css } from '@emotion/css';
import React from 'react';

import { GrafanaTheme2 } from '@grafana/data';

import { useStyles2 } from '../../themes';
import { ToolbarButton, ToolbarButtonVariant } from '../ToolbarButton/ToolbarButton';

export interface Props {
  className?: string;
  message?: string;
  onClick?: (e: React.SyntheticEvent) => void;
  variant: ToolbarButtonVariant;
  ariaLabel?: string;
}

export function PanelStatus({ className, message, onClick, ariaLabel = 'status', variant }: Props) {
  const styles = useStyles2(getStyles(variant));

  return (
    <ToolbarButton
      className={cx(className, styles.buttonStyles)}
      onClick={onClick}
      variant={variant}
      icon="exclamation-triangle"
      iconSize="md"
      tooltip={message || ''}
      aria-label={ariaLabel}
    />
  );
}

const getStyles = (variant: ToolbarButtonVariant) => (theme: GrafanaTheme2) => {
  const { headerHeight, padding } = theme.components.panel;

  return {
    buttonStyles: css({
      label: 'panel-header-state-button',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: theme.spacing(padding),
      width: theme.spacing(headerHeight),
      height: theme.spacing(headerHeight),
      borderRadius: 0,
      borderWidth: variant === 'active' ? 0 : undefined,
    }),
  };
};
